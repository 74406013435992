import React from 'react'
import { motion } from 'framer-motion'

import ImageCard from '../components/image-card'

const ImageGrid = ({ images, onItemClick }) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  }

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  return (
    <motion.div
      className='image-grid'
      variants={container}
      initial='hidden'
      animate='show'
    >
      {images.map((image, index) => {
        return (
          <motion.div className='p-3' key={index} variants={item}>
            <div
              className='is-clickable'
              onClick={() => onItemClick && onItemClick(index)}
            >
              <ImageCard {...image} />
            </div>
          </motion.div>
        )
      })}
    </motion.div>
  )
}

export default ImageGrid

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'

import ImageGrid from '../components/image-grid'
import PageLayout from '../components/page-layout'

const GalleryPage = ({
  data: {
    allWpMediaItem: { nodes: cookies },
  },
}) => {
  const images = cookies.map(node => {
    return {
      id: node.id,
      src: node.sourceUrl,
      name: node.title,
      alt: node.title,
    }
  })

  const [modalActive, setModalActive] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const openModal = () => {
    setModalActive(true)
  }

  const closeModal = () => {
    setModalActive(false)
  }

  const modalClicked = e => {
    if (
      e.target.classList.contains('image') ||
      e.target.classList.contains('icon') ||
      e.target.classList.contains('fas')
    ) {
      return
    }
    closeModal()
  }

  const paginateImages = offset => {
    const newIndex = wrap(0, images.length, imageIndex + offset)

    setImageIndex(newIndex)
  }

  const handleOnCardClick = index => {
    setImageIndex(index)
    openModal()
  }

  return (
    <PageLayout title='Gallery'>
      <ImageGrid images={images} onItemClick={handleOnCardClick} />

      <div
        className={`modal ${modalActive && 'is-active'}`}
        onClick={modalClicked}
      >
        <div className='modal-background' />
        <div className='modal-content is-clipped'>
          <div className='columns is-mobile is-vcentered'>
            <div className='column is-one-fifth is-flex is-justify-content-flex-start'>
              <span
                className='icon is-large has-text-light is-clickable'
                onClick={() => paginateImages(-1)}
              >
                <i className='fas fa-lg fa-chevron-left'></i>
              </span>
            </div>
            <div className='column image-modal-content is-flex is-justify-content-center'>
              <AnimatePresence initial={false}>
                <motion.div
                  key={imageIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    opacity: { duration: 0.5 },
                  }}
                >
                  <img
                    style={{ maxHeight: '100%' }}
                    src={images[imageIndex].src}
                    alt={images[imageIndex].name}
                    className='image'
                  />
                </motion.div>
              </AnimatePresence>
            </div>
            <div className='column is-one-fifth is-flex is-justify-content-flex-end'>
              <span
                className='icon is-large has-text-light is-clickable'
                onClick={() => paginateImages(1)}
              >
                <i className='fas fa-lg fa-chevron-right'></i>
              </span>
            </div>
          </div>
        </div>
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={closeModal}
        ></button>
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query GalleryPageQuery {
    allWpMediaItem(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Cookie" } } } }
      }
    ) {
      nodes {
        id
        title
        sourceUrl
      }
    }
  }
`

export default GalleryPage
